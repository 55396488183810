import Head from 'next/head'
import Cookies from "js-cookie";
import dynamic from 'next/dynamic'
import { useRouter } from "next/router"
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Env from 'Consts/env'
import { getBanner } from 'Helpers/fetch'
import Vars from '../consts/vars'
import {
  ScrollToUp,
  fbpixel,
  fetchNavmenu,
  injectAltImage,
  setDataLayer,
  setGTM,
  setGTM_2,
  setGoogleAnalytics,
  setGoogleAnalytics_,
  Fetching,
  comSocre,
} from '../helpers/utils'
import NavbarHeader from "@fdn/navbar_header";
import { loadSearchDefault } from "@/Store/SearchDefault/lib/actions";
import { loadQuickSearch } from "@/Store/QuickSearch/lib/actions";
import SearchDefault from "./search_default/searchDefault";
import QuickSearch from "./quick_search/quickSearch";
import AlloMain from '@fdn/allo_profile_migration'
import AlloExploreModal from 'Components/allo_modal/AlloExploreModal'
import MegaMenu from '@fdn/mega_menu'
import Footer from './footer'
import Banner from 'Components/banner/banner'
import { loadCompleteProfile } from '@/Store/CompleteProfile/lib/actions';
const AlloProfilePopupSection = dynamic(() => import('Components/allo/AlloProfilePopupSection'), {
  ssr: false,
});

fetchNavmenu()

export default function Layout({
  children,
  isWebview,
  self,
  title,
  is_mobile,
  data_article,
  page_type,
  ads_head,
  ads_foot,
  isShowNavbarHeader = isShowNavbarHeader || true,
  isShowFooter = isShowFooter || true,
}) {
  const isMobile = is_mobile || false
  const dispatch = useDispatch()
  const router = useRouter()

  const getData = useSelector((state) => state)
  const [searchCampaign, setSeacrhCampaign] = useState([]);
  const [searchText, setSearchText] = useState('')
  const [isShowSearch, setShowSearch] = useState(false)
  const [isShowSearchResult, setShowSearchResult] = useState(false)
  const [isShowSearchInput, setShowSearchInput] = useState(false)
  const [isHidetopMenu, setToggleTopMenu] = useState(false)
  const [navHeight, setNavHeight] = useState(0)
  const [IsShowSidebar, setShowSidebar] = useState(false)

  const [IsShowAlloPopup, SetIsShowAlloPopup] = useState(false)

  const [isAlloExplorePopup, setIsAlloExplorePopup] = useState(false)
  const [ribbonBanner, setRibbonBanner] = useState({})

	const [DataHistory, setDataHistory] = useState([]);
	const [IsLoadingSearch, setIsLoadingSearch] = useState(false);
	const [IsLoadingDefault, setIsLoadingDefault] = useState(false);

  const updateSearchText = (e) => {
    setSearchText(e.target.value)
    if (
      (searchText.length >= 2 && !isMobile) ||
      (searchText.length > 1 && isMobile)
    ) {
      setShowSearch(true)
      setShowSearchResult(true)
    } else {
      // setShowSearch(false)
      setShowSearchResult(false)
    }
  }

  const onSetDataLayer = (keyword = "", type = "", action = "click") => {

		setDataLayer({
			event: "typing_keyword_on_search",
			data: {
				user_keywords_referral: `${getData.storeSelf?.data?.username || "guest"}, ${searchText}, fromglobalheaderfdeditorial${router.pathname.split("/")[1].replace(/[-]/g, "")}, ${action} ${type} ${keyword}, ${isMobile ? "mobile" : "desktop"}`,
			},
		}, "GTM");

		setDataLayer({
			event: "typing_keyword_on_search",
			device: `${isMobile ? "mobile" : "desktop"}`,
			is_login: !!getData.storeSelf?.data?.username,
			keyword: searchText,
			referral: `fromglobalheaderfdeditorial${router.pathname.split("/")[1].replace(/[-]/g, "")}`,
			username: `${getData.storeSelf?.data?.username || "guest"}`,
			action,
			type,
			title: keyword,
		}, "GTM");

	};

  const handleGoToSearchPage = (value) => {
    window.location.href = `${
      process.env.NEXT_PUBLIC_REVIEWS_DOMAIN
    }/search?q=${encodeURIComponent(value || searchText)}&tab=all`
  }

  const handleSubmitHistory = (value) => {

    Fetching({
			method: "POST",
			url: "submitHistorySearch",
			data: {
				data: {

					keyword: value || searchText,
				},
				authorization: Cookies.get("token"),
			},
		})
			.then((res) => {

        handleGoToSearchPage(value)

				if (res.status === 200 || res.status === 201) {

					setDataHistory([...DataHistory, searchText]);

				} else {

					console.log(`${res.data.meta.msg}, "ini status di else then"`);

				}

			})
			.catch((err) => {

				console.log(`Error get data: ${err}`);

			});

  }

  const submitSearch = (e, value) => {
    e.preventDefault()
    onSetDataLayer("", "", "enter");

    const authorization = Cookies.get("token");

		if (!authorization) {

			handleGoToSearchPage(value);

		} else {

			handleSubmitHistory(value);

		}

  }

  const showSearch = () => {
    if (!is_mobile) {
      setShowSearch(true)
    } else {
      handleReloadData()
      setShowSearchInput(true)
      setShowSearch(true);
    }
  }

  const getRibbonBanner = (placement) => {


    if (isMobile !== null) {
      getBanner({
        placement: placement,
      }).then((data) => {

        if (data?.data?.length > 0) {

          setRibbonBanner(() => ({
            image: data?.data[0]?.image,
            url: data?.data[0]?.url,
          }));

        }

      }).catch((err) => {

        console.log("Error Ribbon Banner", err);

      });
    }

	};

  useEffect(() => {
    setDataHistory(getData.storeSearchDefault?.data?.history)
  }, [getData.storeSearchDefault?.data?.history])

  useEffect(() => {

    // const dataLayerObj = {
    //   event: "typing_keyword_on_search",
    //   username: getData.storeSelf?.data?.username || "guest",
    //   is_login: !!getData.storeSelf?.data?.username,
    //   device: isMobile ? "mobile" : "desktop",
    //   keyword: searchText,
    //   referral: `fromglobalheaderfdeditorial${router.asPath.split("/")[1].replace(/[-]/g, "")}`,
    // }

    let interval
    if (
      (searchText.length >= 3 && !isMobile)
			|| (searchText.length > 2 && isMobile)
    ) {
      setIsLoadingSearch(true);
      interval = setTimeout(() => {

        onSetDataLayer("", "", "typing");

        // setDataLayer(dataLayerObj, "GTM");

        dispatch(
					loadQuickSearch({
						payload: {
							headers: Env.HEADERS,
							is_server: true,
							keyword: searchText.length ? searchText.replace(/[^a-zA-Z0-9_. -]/g, "") : "",
						},
					}),
				);
      }, 1000)
    }

    return () => {
      setIsLoadingSearch(false);
      clearTimeout(interval)
    }
  }, [searchText])

  useEffect(() => {

		// const auth = Cookies.get("token");

		Fetching({
			method: "GET",
			url: "getSearchCampaign",
			payload: {
				headers: Env.HEADERS,
			},
		})
			.then((res) => {

				if (res.data.meta.code === 200) {

					setSeacrhCampaign(res.data.data);

				} else {

					console.log(`${res.data.meta.msg}`);

				}

			})
			.catch((err) => {

				console.log(`Error get data: ${err}`);

			});

	}, []);

  const handleReloadData = () => {

		dispatch(
			loadSearchDefault({
				headers: Env.HEADERS,
				is_server: true,
				Authorization: Cookies.get("token") || "",
			}),
		);

	};

  const searchOnClick = () => {

		setShowSearch(true);
		if (searchText.length <= 0
			&& !getData.storeSearchDefault?.data?.trending
			&& getData.storeSearchDefault?.meta?.code !== 200) {

			dispatch(
				loadSearchDefault({
					headers: Env.HEADERS,
					is_server: true,
					Authorization: Cookies.get("token") || "",
				}),
			);

		}

		setDataLayer({
			event: "click_search",
			username: getData.storeSelf?.data?.username || "guest",
			is_login: !!getData.storeSelf?.data?.username,
			device: isMobile ? "mobile" : "desktop",
			keyword: searchText,
			referral: `fromglobalheaderfdeditorial${router.pathname.split("/")[1].replace(/[-]/g, "")}`,
		}, "GTM");

	};

	const searchInput = () => {

		setShowSearchResult(true);
		setShowSearch(true);

	};

  const deleteHistorySearch = (params) => {

		Fetching({
			method: "PATCH",
			url: "deleteHistorySearch",
			data: {
				data: {

					keywords: params,
				},
				authorization: Cookies.get("token") || "",
			},
		})
			.then((res) => {

				if (res.status === 200 || res.status === 201) {

					handleReloadData();

				}

			})
			.catch((err) => {

				console.log(`Error get data: ${err}`);

			});

	};

  const clearHistorySearch = () => {

		Fetching({
			method: "DELETE",
			url: "clearHistorySearch",
			data: {
				authorization: Cookies.get("token") || "",
			},
		})
			.catch((err) => {

				console.log(`Error get data: ${err}`);

			});

	};

	useEffect(() => {

		if (getData.storeQuickSearch?.status === "fetch_loading" || getData.storeQuickSearch?.status === "fetch_init") {

			setDataHistory(getData.storeSearchDefault?.data?.history);
			setIsLoadingSearch(true);

		} else {

			setIsLoadingSearch(false);

		}

	}, [getData.storeQuickSearch.status]);

	useEffect(() => {

		if (getData.storeSearchDefault?.status === "fetch_loading" || getData.storeSearchDefault?.status === "fetch_init") {

			setIsLoadingDefault(true);

		} else {

			setIsLoadingDefault(false);
      setIsLoadingSearch(false);

		}

	}, [getData.storeSearchDefault.status]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    dataLayer = dataLayer.filter((item) => !item.page_type)

    const dataContent =
      data_article && data_article.rendered_content
        ? !!data_article.rendered_content.match(/<iframe/g)
          ? 'video'
          : !!data_article.rendered_content.match(/<img/g)
          ? data_article.rendered_content.match(/<img/g).length > 1
            ? 'multiplephoto'
            : 'singlephoto'
          : ''
        : ''
    const objArticle = data_article
      ? {
          category:
            data_article &&
            data_article.category &&
            data_article.category.length
              ? data_article.category[0].name
              : '',
          tag:
            data_article &&
            data_article.tags &&
            data_article.tags.map((item) => item.name).join(', '),
          created_date: data_article.created_at,
          publish_date: data_article.updated_at,
          author_name: data_article.author.name,
          title: data_article.title,
          page_size: data_article.pages,
          page_number: data_article.current_page,
          content_type:
            data_article.pages > 1
              ? 'multiplepage'
              : 'singlepage' + dataContent !== ''
              ? ', ' + dataContent
              : '',
        }
      : {}

    const objSelf =
      self && self.data && self.data.id
        ? {
            user_id: self.data.id || '',
            skin_type: self.data.beauty_profile.skin_type_name || '',
            skin_tone: self.data.beauty_profile.skin_tone_name || '',
            skin_undertone: self.data.beauty_profile.skin_undertone_name || '',
            hair_type: self.data.beauty_profile.hair_texture_name || '',
            colored_hair:
              self.data.beauty_profile.hair_color === 1 ? 'yes' : 'no',
            hijaber: self.data.beauty_profile.is_hijab === 1 ? 'yes' : 'no',
            skin_concern:
              self.data.beauty_concern.skin.length > 0
                ? self.data.beauty_concern.skin
                    .map((data) => data.name)
                    .join(', ')
                : '',
            body_concern:
              self.data.beauty_concern.body.length > 0
                ? self.data.beauty_concern.body
                    .map((data) => data.name)
                    .join(', ')
                : '',
            hair_concern:
              self.data.beauty_concern.hair.length > 0
                ? self.data.beauty_concern.hair
                    .map((data) => data.name)
                    .join(', ')
                : '',
          }
        : {}
    let gtm = {
      ...objArticle,
      ...objSelf,
      page_type: page_type || '',
      page_load_time: '',
      platform: is_mobile ? 'Mobile' : 'Desktop',
    }

    dataLayer.push(gtm)
  }, [])

  useEffect(() => {
    // handling ads
    setTimeout(() => {
      var adsmediumrectangle = document.getElementsByClassName(
          'ads-medium-rectangle',
        ),
        adsmediumrectangle1 = document.getElementsByClassName(
          'ads-medium-rectangle1',
        ),
        adsmediumrectangle2 = document.getElementsByClassName(
          'ads-medium-rectangle2',
        ),
        adsbillboard = document.getElementsByClassName('ads-billboard'),
        adsWrapper = []

      if (adsmediumrectangle.length) {
        Object.entries(adsmediumrectangle).map((item, index) => {
          if (
            item[1].innerHTML === '' ||
            item[1].innerHTML === ' ' ||
            item[1].offsetHeight == 0
          ) {
            adsWrapper.push(adsmediumrectangle[index].parentNode)
          }
        })
      }

      if (adsmediumrectangle1.length) {
        Object.entries(adsmediumrectangle1).map((item, index) => {
          if (
            item[1].innerHTML === '' ||
            item[1].innerHTML === ' ' ||
            item[1].offsetHeight == 0
          ) {
            adsWrapper.push(adsmediumrectangle1[index].parentNode)
          }
        })
      }

      if (adsmediumrectangle2.length) {
        Object.entries(adsmediumrectangle2).map((item, index) => {
          if (
            item[1].innerHTML === '' ||
            item[1].innerHTML === ' ' ||
            item[1].offsetHeight == 0
          ) {
            adsWrapper.push(adsmediumrectangle2[index].parentNode)
          }
        })
      }

      if (adsbillboard.length) {
        Object.entries(adsbillboard).map((item, index) => {
          if (
            item[1].innerHTML === '' ||
            item[1].innerHTML === ' ' ||
            item[1].offsetHeight == 0
          ) {
            adsWrapper.push(adsbillboard[index])
          }
        })
      }

      adsWrapper.map((item) =>
        item.innerHTML !== '' || item.innerHTML !== ' '
          ? (item.innerHTML = '')
          : null,
      )

      if (!isMobile) {
        var beautyFilesSection = document.getElementsByClassName(
          'beauty-files-section',
        )

        if (beautyFilesSection.length > 0) {
          var adsBeautyFiles = beautyFilesSection[0].getElementsByClassName(
            'ads-medium-rectangle',
          )

          if (adsBeautyFiles.length === 0) {
            beautyFilesSection[0].classList.add('ads-empty')
          } else {
            beautyFilesSection[0].classList.remove('ads-empty')
          }
        }
      }

      injectAltImage('btn_ads_close_top_frame', 'close button ads top')
      injectAltImage('btn_ads_close_bottom_frame', 'close button ads bottom')
    }, 5000)
  }, [])

  useEffect(() => {

    window.addEventListener('load', function () {
      if (
        !isMobile &&
        document.getElementsByClassName('navbar-header').length
      ) {
        window.addEventListener('scroll', () => {
          if (document.documentElement.scrollTop > 84) {
            setToggleTopMenu(true)
          } else {
            setToggleTopMenu(false)
          }
        })
      }
    })

    if (
      router?.asPath?.includes('webview') &&
      document.getElementById('vidwidget')
    ) {
      document.getElementById('vidwidget').style.display = 'none'
    }


  }, [])

  useEffect(() => {

    // Allo cookies check
    if (process.env.NEXT_PUBLIC_ALLO_STATUS === "ON") {

      if (self?.status !== "fetch_init" && !isWebview) {

        if (!Cookies.get('alloPopup') && (!self || !self.data || (self.status === "fetch_result" && !self.data.mpc_id))) {
          Cookies.set(
            'alloPopup',
            JSON.stringify({
              isShow: true,
              expiryTime: null,
            }),
            {
              domain: `.femaledaily.${
                ["canary", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? 'com' : 'net'
              }`,
            },
          )
          SetIsShowAlloPopup(true)
        } else if (
          Cookies.get('alloPopup') &&
          self &&
          self.data &&
          self.data.mpc_id
        ) {
          Cookies.remove('alloPopup', {
            domain: `.femaledaily.${
              ["canary", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? 'com' : 'net'
            }`,
          })
        } else {
          const checkAlloCookie = () => {
            try {
              JSON.parse(Cookies.get('alloPopup'))
            } catch (e) {
              return false
            }
            return JSON.parse(Cookies.get('alloPopup'))
          }

          const alloPopupCookie = checkAlloCookie()

          if (typeof alloPopupCookie === 'object') {
            if (alloPopupCookie.isShow === true) {
              SetIsShowAlloPopup(true)
            } else if (
              alloPopupCookie.isShow === false &&
              alloPopupCookie.expiryTime < new Date().getTime()
            ) {
              SetIsShowAlloPopup(true)
              Cookies.set(
                'alloPopup',
                JSON.stringify({
                  isShow: true,
                  expiryTime: null,
                }),
                {
                  domain: `.femaledaily.${
                    ["canary", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV)
                      ? 'com'
                      : 'net'
                  }`,
                },
              )
            }
          } else {
            Cookies.remove('alloPopup')
            Cookies.set(
              'alloPopup',
              JSON.stringify({
                isShow: true,
                expiryTime: null,
              }),
              {
                domain: `.femaledaily.${
                  ["canary", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? 'com' : 'net'
                }`,
              },
            )
          }
        }

      }

    }

  }, [self?.data]);

  const handleAlloMenuNavbar = () => {
    if (isMobile) {
      setShowSidebar((state) => !state)
    }

    setIsAlloExplorePopup(true)
  }

  const handleCloseModalAllo = () => {
    setIsAlloExplorePopup(false)
  }

  useEffect(() => {

    const placeholderHeight = setTimeout(() => {

      const navbarHeight = 	document.getElementsByClassName("navbar-header").length ? document.getElementsByClassName("navbar-header")[0].offsetHeight : 0;
      setNavHeight(() => navbarHeight)

    }, 4000);

    return () => clearTimeout(placeholderHeight);

  }, [])

  useEffect(() => {
    const ribbonClear = setTimeout(() => {
      if (isMobile !== null) {
        // getRibbon
        getRibbonBanner(!isMobile ?  "ribbon_banner_desktop" : "ribbon_banner_mobile")
      }
    }, 3000);

    return () => clearTimeout(ribbonClear)
  }, [isMobile])

  const handleLogin = () => {
    if (process.env.NEXT_PUBLIC_ALLO_STATUS === "ON") {

      if (isMobile) {
        SetIsShowAlloPopup(!IsShowAlloPopup)
        setShowSidebar(false)
      } else {
        SetIsShowAlloPopup(!IsShowAlloPopup)
      }

    } else {

      const fullPageUrl = window.location.href;
      window.location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}?url=${fullPageUrl}`;

    }
  }

  const dataTitle =
    typeof title !== 'undefined'
      ? `Female Daily Editorial - ${title}`
      : 'Female Daily Editorial - Info Makeup, Skincare, Trend Kecantikan'

  useEffect(() => {

    if (self?.data?.id) {
      dispatch(
        loadCompleteProfile({
          payload: {
            Authorization: Cookies.get("token")
          },
        }),
      );
    }

  }, []);
  const [isUserVerified, setUserVerified] = useState(null);
  const checkUserVerified = () => {

    const completingData = getData.storeCompleting?.data?.data;
    if (process.env.NEXT_PUBLIC_ALLO_STATUS === "ON") {
      if (completingData?.is_profile_complete) {
        return true;
      } else if (completingData?.account_status?.filter((completion) => completion?.description === "complete_account_allo" && completion?.status === true).length > 0) {
        return true;
      }
      return false;
    }

    return Array.isArray(completingData) ? completingData.filter((completion) => (completion?.step === "complete_verify_email" || completion?.step === "complete_verify_phone") && completion?.status === true).length === 2 : null;

  };
  useEffect(() => {

    if (getData.storeCompleting?.status === "fetch_result") {

      setUserVerified(checkUserVerified());

    }

  }, [getData.storeCompleting?.status]);

  return (
    <div>
      <Head>
        <title>{dataTitle}</title>
			  <link rel="icon" href="/favicon.ico" />
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/font/icomoon/styles.css"
        />
        <link rel="stylesheet" type="text/css" href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/styles/global.css`}`} />
        <link rel="stylesheet" href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/style/bundle.css" />
        <link rel="stylesheet" type="text/css" href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/styles/fontastic/styles.css`}`} />
        <link
          rel="shortcut icon"
          href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/fdn_logo_144.png`}`}
        />
        {["canary", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
          /* ALEXA */
          <script
            async
            custom-element="amp-analytics"
            src="https://cdn.ampproject.org/v0/amp-analytics-0.1.js"
          ></script>
        ) : null}
        <script async src="https://newrevive.detik.com/delivery/asyncjs.php" />
        <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"/>
        {!["canary", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
          <meta name="robots" content="noindex" />
        ) : null}
        <script dangerouslySetInnerHTML={setGTM()} />

        <script type="text/javascript" dangerouslySetInnerHTML={fbpixel()} />

        {["canary", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
          /* COMSOCRE */
          <script type="text/javascript" dangerouslySetInnerHTML={comSocre()} />
        ) : null}

        {["canary", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
          <script type="text/javascript" dangerouslySetInnerHTML={setGTM_2()} />
        ) : null}

        {["canary", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
          <script dangerouslySetInnerHTML={setGoogleAnalytics()} />
        ) : (
          ''
        )}

        <link
          href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;600;700&family=Poppins:wght@400;500;600&family=Roboto:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />

        {/* style global fdn */}
        <link rel="stylesheet" type="text/css" href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/style/bundle.css" />
        <link rel="stylesheet" type="text/css" href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/fonts/fdnfonts.css" />

        {/* widget */}
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"
          rel="stylesheet"
        />

        <script src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/fingerprint/fingerprint.js" />
        <script src={`https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/widgets/video/${["canary", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? 'production' : 'staging'}/app.fdn-video-widget.js`} />

        {process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? (
          <script
            dangerouslySetInnerHTML={{
              __html: `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}
        h._hjSettings={hjid:2515136,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `,
            }}
          ></script>
        ) : null}

      </Head>

      {["canary", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
        <amp-analytics
          type="alexametrics"
          dangerouslySetInnerHTML={{
            __html: `
			<script type="application/json"> {"vars": { "atrk_acct": "5TYNs1O7kI20L7", "domain": "editorial.femaledaily.com" }}</script>`,
          }}
        ></amp-analytics>
      ) : (
        ''
      )}

      <div dangerouslySetInnerHTML={{ __html: ads_head }} />
      <div dangerouslySetInnerHTML={{ __html: ads_foot }} />

      {isShowNavbarHeader && (
        <NavbarHeader
          is_mobile={isMobile}
          isNewIcon
          nav_width="1200px"
          type="monochrome"
          self={self.data}
          is_user_verified={isUserVerified}
          nav_position={'fixed'}
          menu={Vars.top_menu}
          logo_img={
            'https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/logo-fd.svg'
          }
          main_link={process.env.NEXT_PUBLIC_ROOT_DOMAIN}
          home_link={process.env.NEXT_PUBLIC_ROOT_HOME}
          reviews_link={process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}
          talk_link={process.env.NEXT_PUBLIC_TALK_DOMAIN}
          sso_link={process.env.NEXT_PUBLIC_SSO_DOMAIN}
          sidebar_config={Vars.config_sidebar}
          logo_id="id_logo_editorial"
          search_input_id="id_search_input_field_editorial"
          button_login_id="id_loginsignup_editorial"
          button_cart_id="id_carteditorial"
          button_notif_id="id_notifeditorial"
          submit_search={submitSearch}
          search_text={searchText}
          update_search_text={updateSearchText}
          close_search_wrapper={() => {
            setSearchText("");
            setShowSearchInput(false);
            setShowSearch(false);
          }}
          is_show_search_suggest={isShowSearch}
          is_show_search_result={isShowSearchResult}
          is_show_search={isShowSearchInput}
          is_show_sidebar={IsShowSidebar}
          toggle_sidebar={() => setShowSidebar(!IsShowSidebar)}
          show_search={showSearch}
          hide_search={() => {
            setSearchText("");
            setShowSearch(false);
            setShowSearchInput(false);
          }}
          is_hide_top_menu={isHidetopMenu}
          eventImage={ribbonBanner?.image}
          eventURL={ribbonBanner?.url}
          profile_link={
            self.data
              ? process.env.NEXT_PUBLIC_SSO_DOMAIN +
                '/user/' +
                self.data.username
              : null
          }
          setting_link={process.env.NEXT_PUBLIC_SSO_DOMAIN + '/my/account'}
          SisterSites={[
            {
              name: 'Mommies Daily',
              value: 'md',
              pathname: process.env.NEXT_PUBLIC_MD_DOMAIN,
              query: '',
              visible: true,
              new: false,
            },
            {
              name: 'Female Daily Studio',
              value: 'bs',
              pathname: process.env.NEXT_PUBLIC_ECM_DOMAIN,
              query: '',
              visible: true,
              new: false,
            },
            {
              name: 'Girls Beyond',
              value: 'gb',
              pathname: process.env.NEXT_PUBLIC_GB_DOMAIN,
              query: '',
              visible: true,
              new: false,
            },
          ]}
          search_on_click={searchOnClick}
          search_on_keyDown={searchInput}
          search_placeholder={searchCampaign.campaign_name}
          onBlurInput={() => false}
          onClickLoginSignup={() => handleLogin()}
          // allo explore handle
          handleAlloExplorer={handleAlloMenuNavbar}
          profilePopupCustomElement={
            self?.data?.mpc_id && process.env.NEXT_PUBLIC_ALLO_STATUS === "ON"
              && (
                <>
                  <AlloProfilePopupSection
                    onClickAlloCoupon={handleAlloMenuNavbar}
                  />
                </>
              )
          }
        >
          {
            isShowSearch && isMobile?
            null
            :
            <NavbarHeader.ChildrenWrapper>
              <div className="mega-menu-wrapper">
                <MegaMenu
                  is_mobile={isMobile}
                  align={'left'}
                  menus={Vars.mega_menu}
                />
              </div>
            </NavbarHeader.ChildrenWrapper>
          }

          {
					isShowSearch &&
						<NavbarHeader.SearchWrapper>
              {
              IsLoadingSearch?
									<div className="loader-wrapper">
										<img
											src="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/global-header/loader_blink.gif"
											className="loader-gif"
											alt="loader-blink"
										/>
									</div>
                  : searchText.length >= 3 ?
									<QuickSearch
										isMobile={isMobile}
										defaultPictureProfile={Vars.imageBrokenDefault.user}
                    defaultPicture={Vars.imageBrokenDefault.square}
										limitDataBrand={5}
										limitDataProduct={4}
										limitDataService={4}
										limitDataArticle={3}
										limitDataTalkTopic={2}
										limitDataTalkGroup={3}
										limitDataUser={3}
										limitDataSuggest={3}
										talk_group={getData.storeQuickSearch?.data?.results?.talk_groups || []}
										talk_topics={getData.storeQuickSearch?.data?.results?.talk_topics || []}
										dataBrand={getData.storeQuickSearch?.data?.results?.brands || []}
										dataProduct={getData.storeQuickSearch?.data?.results?.products || []}
										dataService={getData.storeQuickSearch?.data?.results?.services || []}
										dataArticle={getData.storeQuickSearch?.data?.results?.articles || []}
										dataUsers={getData.storeQuickSearch?.data?.results?.users || []}
										suggestions={getData.storeQuickSearch?.data?.suggestions || []}
										submitSearch={submitSearch}
                    onSetDataLayer={onSetDataLayer}
                    searchText={searchText}
									/>
                  :
								<SearchDefault
									isMobile={isMobile}
									IsLoadingDefault={IsLoadingDefault}
									limitDataBrand={5}
									limitDataProduct={4}
									limitDataArticle={3}
									limitDataPromotion={3}
									limitDataHistory={5}
									dataPromotion={getData.storeSearchDefault?.data?.promotion?.section}
									titlePromotion={getData.storeSearchDefault?.data?.promotion?.name}
									searchHistory={DataHistory}
									titlePopular={getData.storeSearchDefault?.data?.popular?.name}
									dataBrand={getData.storeSearchDefault?.data?.popular?.section?.filter((item) => item.type === "brand")[0]?.data || []}
									dataProduct={getData.storeSearchDefault?.data?.popular?.section?.filter((item) => item.type === "product")[0]?.data || []}
									dataArticle={getData.storeSearchDefault?.data?.popular?.section?.filter((item) => item.type === "article")[0]?.data || []}
									trending={getData.storeSearchDefault?.data?.trendings}
									searchText={searchText}
									deleteHistorySearch={deleteHistorySearch}
                  clearHistorySearch={clearHistorySearch}
									defaultPicture={Vars.imageBrokenDefault.rectangle}
									submitSearch={submitSearch}
                  onSetDataLayer={onSetDataLayer}
								/>
              }
                </NavbarHeader.SearchWrapper>
				}
        </NavbarHeader>
      )}

      <style>
        {`

          .search-input-field input {
            display: block;
            max-width: calc(100% - 32px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .search-button:focus, .search-input-field input:focus::placeholder{
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .search-input-field::placeholder, .search-input-field:focus::placeholder, .search-input-field input::placeholder{
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis !important;
            max-width: 95%;
          }
          .navbar-header {
            background-color: #FFF;
          }

          .navbar-inner-content-wrapper {
            max-width: 1200px;
          }

          .mega-menu-wrapper {
            max-width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
          }

          .search-input-field input {
            max-width: calc(100% - 32px);
          }

          .sub-menu-title a {
            text-transform: uppercase;
          }

          .menu-popup {
            max-width: calc(100% - 48px);
            z-index: 2;
          }

          img.sub-menu-item {
            max-width: 100%;
          }
          .fdn-modal {
            animation: none !important;
          }

          @media (max-width: 768px) {
            .navbar-header .btn-close {
              margin-left: 0;
            }

            .navbar-header .mega-menu {
              padding-bottom: 0;
            }

            .mega-menu-wrapper {
              width: 100%;
              max-width: 100%;
              margin-top: 12px;
              display: unset;
              justify-content: unset;
            }

            .navbar-header .search-popup-content {
              max-width: calc(100% - 32px);
            }

            .menu-popup {
              max-width: unset;
            }

            .menu-popup-title {
              justify-content: space-between;
            }
          }

					.top-menu .menus a{
						cursor: pointer;
					}

          @media (max-width: 991px) {
						.profile-popup {
							width: 100%;
						}
					}
          .top-menu {
            transition: height .5s ease;
            height: 41px;
          }

          .navbar-header-placeholder {
						height: ${navHeight}px;
					}
        `}
      </style>
      <style jsx global>
        {`
          a {
            text-decoration: none;
          }

          body {
            margin: 0px;
            max-width: 100%;
            overflow-x: hidden;
          }
          html {
            scroll-padding-top: 150px;
          }
          {/* temporary hide allo explore menu mobile */}
          .sidebar .profile-option:nth-child(1) {
            display: ${process.env.NEXT_PUBLIC_ALLO_STATUS === "ON" ? "block" : "none"};
          }
        `}
      </style>
      <div className="main-content">
        {!!isMobile ? null : (
          <div className="top-frame">
            <Banner
              name="topframe"
              placeholder="topframe"
              is_mobile={isMobile}
              class_name="topframe"
            />
          </div>
        )}
        {children}
      </div>
      <style>
        {`.ads-topframe {
					// top: ${isHidetopMenu ? '143px' : '196px'};
					position: fixed;
					left: 0;
					right: 0;
					z-index: 2;
				}`}
      </style>

      {!!isMobile && !router?.asPath?.includes('webview') ? (
        <Banner
          name="stickystaticbanner"
          placeholder="stickystaticbanner"
          is_mobile={isMobile}
          class_name="ads-stickystaticbanner"
        />
      ) : null}

      {!isMobile ? (
        <Banner
          name="bottomframe"
          placeholder="bottomframe"
          is_mobile={isMobile}
          class_name="ads-bottomframe"
        />
      ) : null}
      <style>
        {`
				.ads-bottomframe,
				.ads-stickystaticbanner {
					width: 100%;
					position: fixed;
					bottom: -4px;
					display: flex;
					justify-content: center;
					z-index:2
				}
				.ads-bottomframe {
					bottom: -4px;
				}
				`}
      </style>
      {["canary", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
        <script dangerouslySetInnerHTML={setGoogleAnalytics_()} />
      ) : (
        ''
      )}

      {IsShowAlloPopup && process.env.NEXT_PUBLIC_ALLO_STATUS === "ON" && (
        <>
          <AlloMain
            isMobile={isMobile}
            query={{}}
            hostDomain={".femaledaily." + ["canary", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? 'com' : 'net'}
            ssoDomain={process.env.NEXT_PUBLIC_SSO_DOMAIN}
            // errorOffsetTop={state.navHeight + "px"}
            closeElement={() => SetIsShowAlloPopup(false)}
            // fetchMPC={(data) => FetchMPC(data)}
            // login={(data) => login(data)}
            // loginVerifyCode={(data) => login_verify_code(data)}
            // forgotPassword={(data) => forgot_password(data)}
            // registerCompleteAccount={(data) => register_complete_account(data)}
            onClickMigrateAccount={() => {
              const pageReferer = window.location.href
              window.location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}?url=${pageReferer}`
            }}
            onClickLoginRegister={() => {
              const pageReferer = window.location.href
              window.location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}?url=${pageReferer}`
            }}
            helpUrl={`${process.env.NEXT_PUBLIC_ROOT_HOME}/help?tab=account`}
          />
          <style>
            {`
                @media (max-width: 767px) {

                  .mpc-info-fd-logo-container img {
                    width: unset;
                    height: unset;
                  }

                }
              `}
          </style>
        </>
      )}
      <AlloExploreModal
        isMobile={isMobile}
        isShow={isAlloExplorePopup}
        onClick={handleCloseModalAllo}
      />

      {isShowFooter && (
        <Footer isMobile={isMobile} handleScrollUp={ScrollToUp} />
      )}
      <style jsx>
        {`
        .loader-wrapper{
						display: flex;
						align-items: center;
						justify-content: center;
					}
          .top-frame {
            position: fixed;
            top: ${isHidetopMenu ? (ribbonBanner?.url ? '150px' : '120px') : (ribbonBanner?.url ? '190px' : '160px')};
            transition: top 0.5s ease;
            left: 0;
            right: 0;
            z-index: 2;
            display: flex;
            justify-content: center;
          }
        `}
      </style>
    </div>
  )
}
